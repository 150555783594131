<template>
  <div class="layouts">
    <div class="title">
      <div>
        <div class="title-left">
          服务通
        </div>
        <!-- <div class="title-ul">
          <el-popover
              placement="bottom"
              trigger="hover">
            <span slot="reference">{{ title }}</span>
            <ul class="navli">
              <li v-for="(item,index) in titleData" :key="index" @click="navClick(item,index)"
                  :class="{titeStyle: index == titleIndex}">{{ item.title }}
              </li>
            </ul>
          </el-popover>
        </div> -->
      </div>
      <!-- <div v-if="titleIndex==2" class="select-row ">1</div> -->
      <div class="x-row select-row ">
        <div class="x-col"></div>
        <div >
          <div class="x-row">
            <label class="select-label x-col-center">切换品牌</label>
            <el-select v-model="checkBrand" placeholder="请选择" @change="getCompanyList">
              <el-option
                  v-for="item in brandList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div>
          <div class="x-row">
            <label class="select-label x-col-center">切换门店</label>
            <el-select v-model="checkCompany" placeholder="请选择" @change="companyListClick">
              <el-option
                  v-for="item in companyList"
                  :key="item.id"
                  :label="item.alias_store_name"
                  :value="item.id"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="login-out">
        <el-popover
            placement="bottom"
            trigger="hover">
                    <span slot="reference">
                      <span>{{ userName }}</span><i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
          <ul class="rntnavli">
            <li v-if=" zhang != 'admin'" @click="edD">修改密码</li>
            <li @click="tuClick()">退出登陆</li>
          </ul>
        </el-popover>
        <!-- <span @click="tuClick()">退出登录</span> -->
      </div>
    </div>
    <div class="cont">
      <div class="nav">
        <el-menu
            :router="true"
            :default-active='rotUrl'
            class="el-menu-vertical-demo"
            background-color="#545c64"
            text-color="#fff"
            active-text-color="#1989fa">
          <div v-for="(value) in navData" :key="value.title+value.path">
            <component class="menu-item"
                       v-if="value.title"
                       :index="value.path"
                       :is="(value.children && value.children.length>0)?'el-submenu':'el-menu-item'">
              <template slot="title" v-if="value.title">
                <i :class="[value.icon]"></i>
                <span>{{ value.title }}</span>
              </template>
              <template v-if="value.children&&value.children.length>0 && value.title">
                <el-menu-item class="menu-item" v-for="(v,i) in value.children" :key="v.path+i" :index="v.path">
                  <i :class="[v.icon]"></i>
                  <span slot="title">{{ v.title }}</span>
                </el-menu-item>
              </template>
            </component>
          </div>
        </el-menu>
      </div>
      <div class="cont-cont">
        <router-view v-if="isShow"></router-view>
      </div>
    </div>
    <!-- 修改密码弹出层 -->
    <el-dialog
        title="修改密码"
        :show-close="false"
        :visible.sync="dialogVisible"
        width="30%">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="95px" class="demo-ruleForm">
        <el-form-item label="旧密码" prop="old">
          <el-input type="password" v-model="ruleForm.old" placeholder="请输入旧密码" autocomplete="off" :clearable="true"
                    onkeypress="javascript:if(event.keyCode == 32)event.returnValue = false"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="pass">
          <el-input type="password" v-model="ruleForm.pass" placeholder="请输入新密码" autocomplete="off"
                    onkeypress="javascript:if(event.keyCode == 32)event.returnValue = false"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPass">
          <el-input type="password" v-model="ruleForm.checkPass" placeholder="再次输入密码，进行确认" autocomplete="off"
                    onkeypress="javascript:if(event.keyCode == 32)event.returnValue = false"></el-input>
        </el-form-item>
      </el-form>
      <div class="anniu">
        <el-button type="info" @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="editClick">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import router from "../router/index";
import {loginOut, updatePassword} from "../api/srv-store";
import {selectBrandList, selectCompanyList} from "../api/srv-store";

export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass');
        }
        callback(); 
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      zhang: localStorage.getItem('account'),
      userName: localStorage.getItem('userName'),
      titleData: [],
      rotUrl: "/envelope",
      navData: [],
      title: "",
      titleIndex: 0,
      brandList: [],
      companyList: [],
      checkBrand: '',
      checkCompany: '',
      isShow: true,
      dialogVisible: false,
      ruleForm: {
        old: '',
        pass: '',
        checkPass: '',
      },
      rules: {
        old: [
          {required: true, message: '请输入旧密码', trigger: 'blur'},
        ],
        pass: [
          {validator: validatePass, trigger: 'blur', required: true}
        ],
        checkPass: [
          {validator: validatePass2, trigger: 'blur', required: true}
        ],
      }
    }
  },
  created() {
    this.rotUrl = this.$route.path;
    let att = JSON.parse(localStorage.getItem('menu_list'))
    console.log(att)
    let arrc = router.options.routes;
    let zu = []
    arrc.map(er =>{
      if(er.path != '/brandManage'){
        zu.push(er)
      }
    })
    if(att.length>0){
      zu.push(att[0])
    }
    let arr = zu
    let atr = []
    arr.map(er => {
      if (er.title) {
        atr.push(er);
      }
    });
    this.titleData = atr;
    if (this.titleData && this.titleData.length > 0) {
      let attr = this.titleData;
      this.heBing(attr);
    }
    this.getBrandList();
  },
  computed: {},
  methods: {
    //获取品牌列表
    getBrandList() {
      selectBrandList().then(Er => {
        if (Er.return_code === 200) {
          this.brandList = Er.data;
          this.checkBrand = Er.data[0].id
          this.getMen(Er.data[0].id);
          localStorage.setItem('brandId', Er.data[0].id)
          // this.$addStorageEvent(1, "brandId", Er.data[0].id);
        }
      });
    },
    //获取门店列表
    getMen(id) {
      selectCompanyList({
        brand_id: id
      }).then(Er => {
        if (Er.return_code === 200) {
          this.checkCompany = Er.data[0].id
          this.companyList = Er.data;
          localStorage.setItem('storesId', Er.data[0].id)
          this.roloadA()
        }
      });
    },
    //选择品牌
    getCompanyList(val) {
      this.getMen(val)
      localStorage.setItem('brandId', val)

    },
    //选择门店
    companyListClick(val) {
      localStorage.setItem('storesId', val)
      this.roloadA()
    },
    heBing(attr) {
      let _this = this;
      attr.map((er, index) => {
        if (er.redirect != this.rotUrl) {
          if (er.children && er.children.length > 0) {
            let att = this.treeConvertToArr(er.children);
            att.map(ec => {
              //子集中包含当前路由
              if (ec.path == this.rotUrl) {
                _this.titleIndex = index;
                _this.title = er.title;
                _this.navData = er.children;
                return;
              }
            });
          }
        } else {
          _this.titleIndex = index;
          this.navData = er.children;
          this.title = er.title;
        }
      });
    },
    tuClick() {
      loginOut({}).then(er => {
        if (er.return_code == 200) {
          localStorage.removeItem("Authorization");
          localStorage.removeItem("menu_list");
          this.$router.replace("/login");
          window.localStorage.clear();
          this.$message({
            message: "退出成功",
            type: "success"
          });
        }
      });
    },
    navClick(row, index) {
      if (this.titleIndex != index) {
        this.title = row.title;
        this.rotUrl = row.path;
        this.$router.push({path: row.path});
        this.navData = row.children;
        this.titleIndex = index;
      }
      if (this.titleIndex == 2) {
        // this.getBrandList();
        // this.getCompanyList();
      }
    },
    //多维数组转平数组
    treeConvertToArr(tree) {
      let arrs = [];
      let result = [];
      arrs = arrs.concat(tree);
      while (arrs.length) {
        let first = arrs.shift(); // 弹出第一个元素
        if (first.children) {
          //如果有children
          arrs = arrs.concat(first.children);
          delete first["children"];
        }
        result.push(first);
      }
      return result;
    },
    roloadA() {
      this.isShow = false
      this.$nextTick(function () {
        this.isShow = true
      })
    },
    edD() {
      if (this.$refs['ruleForm']) {
        this.$refs['ruleForm'].resetFields()
      }
      this.dialogVisible = true
    },
    // 修改密码
    editClick() {
      let _this = this
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          updatePassword({
            account: localStorage.getItem('account'),
            new_passowrd: this.ruleForm.checkPass,
            old_password: this.ruleForm.old
          }).then(er => {
            console.log(er)
            _this.dialogVisible = false
            window.localStorage.clear()
            _this.$router.push('/login');
            _this.$message({
              message: "密码已更改，请重新登陆",
              type: "success"
            });
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.layouts {
  .title {
    padding: 0px 30px;
    height: 60px;
    background: #1989fa;
    display: flex;
    align-items: center;
    color: #fff;

    .select-label {
      padding: 0 10px;
    }

    .title-left {
      font-size: 18px;
    }

    .title-ul {
      margin-left: 20%;
      height: 100%;
      padding: 0px 10px;

      &:hover {
        cursor: pointer;
      }
    }

    .login-out {
      text-align: right;
      width: 10%;

      > span {
        font-size: 16px;

        &:hover {
          cursor: pointer;
        }
      }
    }

    > div {
      &:nth-child(1) {
        width: 50%;
        display: flex;
        align-items: center;
        flex: 1;
      }
    }

    .select-row {
      width: 40%;
    }
  }

  .cont {
    display: flex;
    height: calc(100vh - 60px);

    .nav {
      height: 100%;
    }
  }
}

// 侧边栏
.el-menu {
  width: 200px;
  height: 100%;
}

.cont-cont {
  width: calc(100% - 200px);
  padding: 0px 24px;
  overflow: auto;
  background: #f7fafb;
}

// 头部
/deep/ .el-popover {
  padding: 0px;
}

.el-popover .navli {
  display: flex;
  width: 400px;
  flex-wrap: wrap;

  > li {
    width: 94px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border: 1px solid #3e566e;
    margin: 3px;
    border-radius: 4px;

    &:hover {
      cursor: pointer;
      border: 1px solid #1989fa;
      color: #1989fa;
    }
  }

  > .titeStyle {
    border: 1px solid #1989fa;
    color: #1989fa;
  }
}

.el-popover .rntnavli {
  width: 136px;

  > li {
    height: 40px;
    line-height: 40px;
    padding-left: 12px;

    &:hover {
      background: rgba(247, 250, 251, 1);
      color: #1989fa;
      cursor: pointer;
    }
  }
}

.anniu {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  > button {
    width: 25%;
  }
}
</style>
